<!--
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
-->
<template>
  <div>
    <egw-table :retrieve-data="retrieveData" :after-retrieve="afterProgramInspectionLogList"
      :export-data-file="exportData" :export-file-name="exportFileName" :form-options="formOptions" :columns="columns"
      :index="index" :is-row-click="isRowClick" :info-title="infoTitle" :drawerform="drawerform" :info-title2="infoTitle2"
      :drawerform2="drawerform2" :param="params" :is-echart="isEchart" :serie-name="serieName" @handleLink="handleLink" />
  </div>
</template>

<script>
// 导入发送请求的方法
import { getProgramInspectionLogList, exportProgramInspectionLogsAsExcelFile } from '../api/ajax-program-inspection-log'
export default {
  name: 'ProgramInspectionLog',
  data() {
    return {
      retrieveData: getProgramInspectionLogList,
      exportData: exportProgramInspectionLogsAsExcelFile,
      // 导出的名称
      exportFileName: this.$t('export.programInspectionLogInfo'),
      // 图表的serie
      serieName: {
        cpuUsage: 'CPU使用率',
        networkTraffic: '瞬时网络流量',
        diskSpaceUsage: '存储使用率',
        memoryUsage: '内存使用率'
      },
      // 控制图表显示
      params: undefined,
      isEchart: false,
      // 显示序号
      index: true,
      // 表格表头
      columns: [
        { prop: 'programId', label: this.$t('programInspectionLog.label.programId') },
        { prop: 'name', label: this.$t('programInspectionLog.label.name') },
        { prop: 'serverId', label: this.$t('program.label.serverId') },
        { prop: 'portNumber', label: this.$t('programInspectionLog.label.portNumber') },
        { prop: 'inspectedTime', label: this.$t('programInspectionLog.label.inspectedTime') },
        { prop: 'status', label: this.$t('programInspectionLog.label.status'), type: 'tag' },
        { prop: 'cpuUsage', label: this.$t('programInspectionLog.label.cpuUsage') + '(%)' },
        { prop: 'networkTraffic', label: this.$t('programInspectionLog.label.networkTraffic') + '(MB)' },
        { prop: 'diskSpaceUsage', label: this.$t('programInspectionLog.label.diskSpaceUsage') + '(%)' },
        { prop: 'memoryUsage', label: this.$t('programInspectionLog.label.memoryUsage') + '(%)' },
        { prop: 'alarmType', label: this.$t('programInspectionLog.label.alarmType') }
      ],
      // 点击某一行显示详情信息
      isRowClick: true,
      // 搜索
      formOptions: [
        {
          label: this.$t('programInspectionLog.label.status'), prop: 'status', type: 'select',
          selectOptions: [
            { value: 1, label: '正常' },
            { value: 2, label: '异常告警' }
          ]
        },
        {
          label: this.$t('programInspectionLog.label.inspectedTime'), type: 'date'
        }
      ],
      // 详情标题
      infoTitle: this.$t('program.title.programInfo'),
      // 详情信息的标题和label
      drawerform: [
        { prop: 'programId', label: this.$t('program.label.id') },
        { prop: 'name', label: this.$t('program.label.name') },
        { prop: 'serverId', label: this.$t('program.label.serverId') },
        { prop: 'portNumber', label: this.$t('program.label.portNumber') },
        { prop: 'technologyName', label: this.$t('program.label.technologyName') },
        { prop: 'technologyVersion', label: this.$t('program.label.technologyVersion') },
        { prop: 'contactId', label: this.$t('program.label.contactId') },
        { prop: 'inspectionPeriod', label: this.$t('program.label.inspectionPeriod') },
        { prop: 'memory', label: this.$t('program.label.memory') + '(MB)' },
        { prop: 'diskSpace', label: this.$t('program.label.diskSpace') + '(MB)' },
        { prop: 'registeredUserId', label: this.$t('program.label.registeredUserId') },
        { prop: 'registeredTime', label: this.$t('program.label.registeredTime') },
        { prop: 'updatedUserId', label: this.$t('program.label.updatedUserId') },
        { prop: 'updatedTime', label: this.$t('program.label.updatedTime') },
        { prop: 'remark', label: this.$t('program.label.remark'), span: 24 },
        { prop: 'inspectionConfig', label: this.$t('program.label.inspectionConfig'), span: 24, type: 'textarea' }
      ],
      // 第二个详情标题
      infoTitle2: this.$t('programInspectionLog.title.programInspectionLogInfo'),
      // 第二个详情信息的标题和label
      drawerform2: [
        { prop: 'programId', label: this.$t('programInspectionLog.label.id') },
        { prop: 'inspectedTime', label: this.$t('programInspectionLog.label.inspectedTime') },
        { prop: 'cpuUsage', label: this.$t('programInspectionLog.label.cpuUsage') + '(%)' },
        { prop: 'networkTraffic', label: this.$t('programInspectionLog.label.networkTraffic') + '(MB/s)' },
        { prop: 'diskIo', label: this.$t('programInspectionLog.label.diskIo') + '(MB/s)' },
        { prop: 'alarmMessage', label: this.$t('programInspectionLog.label.alarmMessage') },
        { prop: 'usedDiskSpace', label: this.$t('programInspectionLog.label.usedDiskSpace') + '(MB)' },
        { prop: 'diskSpaceUsage', label: this.$t('programInspectionLog.label.diskSpaceUsage') + '(%)' },
        { prop: 'usedMemory', label: this.$t('programInspectionLog.label.usedMemory') + '(MB)' },
        { prop: 'memoryUsage', label: this.$t('programInspectionLog.label.memoryUsage') + '(%)' },
        { prop: 'status', label: this.$t('programInspectionLog.label.status'), type: 'tag' },
        { prop: 'otherMetrics', label: this.$t('programInspectionLog.label.otherMetrics'), span: 24, type: 'textarea' }
      ]
    }
  },
  created() {
    if (JSON.stringify(this.$route.query) === '{}') {
      console.log('路由没有参数')
    } else {
      this.params = this.$route.query
      this.isEchart = true
      this.formOptions = [{ label: this.$t('programInspectionLog.label.inspectedTime'), type: 'date' }]
    }
  },
  methods: {
    afterProgramInspectionLogList(list) {
      list.forEach(item => {
        item = this.changeStatus(item)
        item = this.changeAlarmType(item)
        item.inspectedTime = this.changeTime(item.inspectedTime)
      })
      console.log(list)
      return list
    },
    /**
    * @methods changeStatus
    * @description 转换巡检状态为i18n对应的字段
    * @param {Object} params 包含巡检状态的数据
    */
    changeStatus(item) {
      console.log('item', item)
      if (item.status === 1) {
        item.status = this.$t('status.normal')
        item.type = ''
      } else if (item.status === 2) {
        item.status = this.$t('status.anomalous')
        item.type = 'danger'
      }
      return item
    },
    /**
    * @methods changeAlarmType
    * @description 将告警类型转换为对应i8n的字段
    * @param {Object} params 包含告警类型的数据
    */
    changeAlarmType(item) {
      if (item.alarmType === 'CPU_USAGE_TOO_HIGH') {
        item.alarmType = this.$t('programInspectionLog.alarmType.cpuUsage')
      } else if (item.alarmType === 'MEMORY_USAGE_TOO_HIGH') {
        item.alarmType = this.$t('programInspectionLog.alarmType.memory')
      } else if (item.alarmType === 'ABNORMAL_NETWORK_TRAFFIC') {
        item.alarmType = this.$t('programInspectionLog.alarmType.networkTraffic')
      } else if (item.alarmType === 'ABNORMAL_DISK_IO') {
        item.alarmType = this.$t('programInspectionLog.alarmType.diskIO')
      }
      return item
    },
    handleLink(data) {
      const id = data.programId
      // console.log(data)
      // this.$router.go(0)
      this.$router.replace({
        path: '/empty',
        query: {
          router: '/programInspectionLog?programId=' + id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
